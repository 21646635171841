import React from "react";
import PropTypes from "prop-types";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { Box } from "@mui/system";
import { Button, Link, Stack } from "@mui/material";
import fetchApi from "../../../lib/axios";

FileAnswer.propTypes = {
  question: PropTypes.object,
  value: PropTypes.any,
  onChange: PropTypes.func,
  onRemoveFile: PropTypes.func,
};

function FileAnswer({ onChange, onRemoveFile, value, question }) {
  const handleUploadFile = async (file, question) => {
    let formData = new FormData();
    formData.append("upload", file);

    try {
      const res = await fetchApi("/file/upload", formData, "post", {
        headers: {
          "content-type": "multipart/form-data",
        },
      });

      if (res) {
        onChange(question.type, question._id, {
          destination: res.destination,
          filename: res.filename,
          url: res.url,
        });
      }
    } catch (error) {
      alert(error.response?.data?.message);
    }
  };

  return (
    <>
      {value ? (
        <Box
          style={{
            display: "inline-flex",
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            style={{
              letterSpacing: ".2px",
              color: "#202124",
              border: "1px solid #dadce0",
              borderRadius: "4px",
              lineHeight: "36px",
              minHeight: "36px",
              padding: "0 3px 0 7px",
            }}
          >
            <InsertPhotoIcon color="warning" />
            <Box>
              <Link
                href={value.answer.url}
                target="_blank"
                style={{
                  maxWidth: "120px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {value.answer.filename}
              </Link>
            </Box>
            <ClearOutlinedIcon
              onClick={() => {
                onRemoveFile(question._id);
              }}
            />
          </Stack>
        </Box>
      ) : (
        <Button
          variant="outlined"
          component="label"
          startIcon={<FileUploadOutlinedIcon />}
        >
          Tải ảnh
          <input
            hidden
            accept="image/*"
            multiple
            type="file"
            onChange={(e) => {
              handleUploadFile(e.target.files[0], question);
            }}
          />
        </Button>
      )}
    </>
  );
}

export default FileAnswer;
