import React from "react";
import { Box, Container, Card, Divider } from "@mui/material";
import tokyolife from "../../assets/image/tokyolife.png";
import format from "../../assets/image/formart.png";
import SurveyBrandType from "../../constants/survey-brand-type";

function Logo({ brand }) {
  return (
    <Box sx={{ py: 1, textAlign: "center" }}>
      <img
        style={{ maxWidth: "85%" }}
        src={SurveyBrandType.TOKYOLIFE === brand ? tokyolife : format}
        className="logo"
        alt="logo"
      />
    </Box>
  );
}

export default Logo;
