function htmlDecode(input) {
  var doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent;
}

function createMarkup(data) {
  return { __html: data };
}

function getKeyByValue(object, value) {
  if (!!object) {
    return Object.keys(object).find((key) => object[key] === value);
  }

  return "";
}

// function convert timestamp to datetime format dd/mm/yyyy hh:mm:ss

function convertTimestamp(timestamp) {
  const date = new Date(timestamp);
  const formattedDate = date.toISOString().slice(0, 19).replace('T', ' ');
  
  return formattedDate;
}

export { htmlDecode, createMarkup, getKeyByValue, convertTimestamp };
