import React from "react";
import PropTypes from "prop-types";
import { Checkbox, FormControlLabel, Stack, TextField } from "@mui/material";
import { createMarkup } from "../../../utils/common";

CheckBoxs.propTypes = {
  question: PropTypes.object,
  onChange: PropTypes.func,
};

function CheckBoxs({ question, onChange }) {
  return (
    <Stack>
      {question.options?.map((option, idx) => (
        <FormControlLabel
          key={`${question._id}-${idx}`}
          control={
            <Checkbox
              value={option.value}
              onChange={(e) =>
                onChange(question.type, `${question._id}`, e.target.value)
              }
            />
          }
          label={
            <div dangerouslySetInnerHTML={createMarkup(option.value)}></div>
          }
        />
      ))}

      {question.allowComment && (
        <FormControlLabel
          key={`${question._id}-comment`}
          sx={{
            paddingLeft: "12px",
            marginTop: "10px",
            mr: 0,
          }}
          fullWidth
          control={
            <TextField
              placeholder="Ý kiến thêm"
              multiline
              fullWidth
              maxRows={4}
              variant="standard"
              onChange={(e) =>
                onChange(question.type, question._id, e.target.value, true)
              }
            />
          }
          label=""
        />
      )}
    </Stack>
  );
}

export default CheckBoxs;
