import React from "react";

function Notfound(props) {
  return (
    <div style={{ textAlign: "center", padding: "20px", minHeight: "100%" }}>
      Page not found
    </div>
  );
}

export default Notfound;
