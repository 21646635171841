import React from "react";
import PropTypes from "prop-types";
import { FormControlLabel, Radio, RadioGroup, TextField } from "@mui/material";
import { createMarkup } from "../../../utils/common";

MultipleChoice.propTypes = {
  question: PropTypes.object,
  onChange: PropTypes.func,
};

function MultipleChoice({ question, onChange }) {
  return (
    <div>
      <RadioGroup
        fullWidth
        onChange={(e) => onChange(question.type, question._id, e.target.value)}
      >
        {question.options?.map((option, idx) => (
          <FormControlLabel
            key={`${question._id}-${idx}`}
            value={option.value}
            control={<Radio />}
            label={
              <div dangerouslySetInnerHTML={createMarkup(option.value)}></div>
            }
          />
        ))}

        {question.allowComment && (
          <FormControlLabel
            key={`${question._id}-comment`}
            sx={{
              paddingLeft: "12px",
              marginTop: "10px",
              mr: 0,
            }}
            fullWidth
            control={
              <TextField
                placeholder="Ý kiến thêm"
                multiline
                fullWidth
                maxRows={4}
                variant="standard"
                onChange={(e) =>
                  onChange(question.type, question._id, e.target.value, true)
                }
              />
            }
            label=""
          />
        )}
      </RadioGroup>
    </div>
  );
}

export default MultipleChoice;
