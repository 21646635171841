import React from "react";
import PropTypes from "prop-types";
import { TextField } from "@mui/material";

ShortAnswer.propTypes = {
  question: PropTypes.object,
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
};

ShortAnswer.defaultValue = {
  question: {},
  onChange: null,
  defaultValue: "",
};


function ShortAnswer({ question, onChange, defaultValue }) {
  return (
    <TextField
      placeholder="Viết câu trả lời"
      multiline
      fullWidth
      defaultValue={defaultValue}
      maxRows={4}
      variant="standard"
      onChange={(e) => onChange(question.type, question._id, e.target.value)}
    />
  );
}

export default ShortAnswer;
