import React from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { createMarkup } from "../../../utils/common";

Dropdown.propTypes = { question: PropTypes.object, onChange: PropTypes.func };

function Dropdown({ question, onChange }) {
  return (
    <Stack sx={{ width: "100%" }}>
      <FormControl fullWidth size="small">
        <InputLabel htmlFor={`${question._id}`}>Chọn câu trả lời</InputLabel>
        <Select
          sx={{ width: 250 }}
          defaultValue={`${
            question.isRequired ? question.options[0]?.value : ""
          }`}
          id={`${question._id}`}
          //   value={answers[question._id]?.value}
          label="Chọn câu trả lời"
          onChange={(e) =>
            onChange(question.type, question._id, e.target.value)
          }
        >
          {!question.isRequired && (
            <MenuItem key={"null"} value="">
              <em>Bỏ chọn</em>
            </MenuItem>
          )}

          {question.options?.map((option, idx) => (
            <MenuItem key={`${question._id}-${idx}`} value={option.value}>
              <div dangerouslySetInnerHTML={createMarkup(option.value)}></div>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {question.allowComment && (
        <FormControlLabel
          key={`${question._id}-comment`}
          sx={{
            paddingLeft: "12px",
            mr: 0,
            marginTop: "15px",
          }}
          fullWidth
          control={
            <TextField
              placeholder="Ý kiến thêm"
              multiline
              fullWidth
              maxRows={4}
              variant="standard"
              onChange={(e) =>
                onChange(question.type, question._id, e.target.value, true)
              }
            />
          }
          label=""
        />
      )}
    </Stack>
  );
}

export default Dropdown;
