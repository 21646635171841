import axios from "axios";
// import LocalStorage from "./localStorage";

const BASE_URL = process.env.REACT_APP_API_ENDPOINT;
const TIMEOUT = 18000;

export const funcFetch = async (
  endPoint,
  data = null,
  method = "get",
  headers = {}
) => {
  headers["Accept-Language"] = "vn";

  //   const token = await LocalStorage.getToken();

  //   if (token) {
  //     headers = {
  //       ...headers,
  //       Authorization: "Bearer " + token,
  //     };
  //   }

  return axios({
    url: BASE_URL + endPoint,
    method,
    headers,
    data,
    timeout: TIMEOUT,
  })
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        return res.data.data;
      }

      console.log(res.error);

      throw new Error(res.error);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

const fetchApi = async (
  endPoint = "",
  data = null,
  method = "get",
  headers = {}
) => {
  return funcFetch(endPoint, data, method, headers);
};

export default fetchApi;
