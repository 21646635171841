import {
    Button,
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Stack,
    Typography,
  } from "@mui/material";
  import { Box, Container } from "@mui/system";
  import React, { useEffect, useRef, useState } from "react";
  import { useParams } from "react-router-dom";
  import fetchApi from "../../lib/axios";
  import SurveyBrandType from "../../constants/survey-brand-type";
  import PropTypes from "prop-types";
  import Barcode from "react-barcode";
  import { exportComponentAsJPEG } from "react-component-export-image";
import Logo from "../../pages/main/Logo";
  
  function Viettinbank({ brand, code }) {
    const componentRef = useRef(null);
  
    return (
      <form
        style={{
          minHeight: "100vh",
          height: "100%",
          background: `${
            SurveyBrandType.TOKYOLIFE === brand ? "#FFEDE8" : " #FAFAFA"
          }`,
        }}
      >
        <Box
          sx={{
            display: "flex",
            height: "100%",
            pb: 3,
          }}
        >
          <Container maxWidth="md">
            <Logo brand={brand} />
            <Box>
              <Card>
                <Stack
                  alignItems="center"
                  sx={{
                    p: 2,
                    borderTop: `6px solid ${
                      SurveyBrandType.TOKYOLIFE === brand ? "#C81F24" : "#656565"
                    }`,
                  }}
                >
                  <Typography variant="h5">
                    <strong>Format đăng ký nhận quà</strong>
                  </Typography>
                </Stack>
                <Divider />
                <Box sx={{ p: 2, textAlign: "center" }}>
                  <div ref={componentRef}>
                    <Typography variant="h4">
                      CHÚC MỪNG QUÝ KHÁCH ĐÃ ĐĂNG KÝ NHẬN ƯU ĐÃI THÀNH CÔNG!
                    </Typography>
                    <Typography
                      style={{
                        fontStyle: "italic",
                        fontWeight: "bold",
                        color: "red",
                      }}
                    >
                      Quý khách vui lòng lưu lại mã này để áp dụng ưu đãi.
                    </Typography>
                    <Typography>
                      <Barcode value={code} />
                    </Typography>
                    <Typography>
                        FORMAT gửi tặng Qúy khách 01 Mã Code 1.000.000đ áp dụng cho hóa đơn từ 2 triệu trở lên.  
                    </Typography>
                    <Typography>
                    Áp dụng với Trang phục, giày, túi, ví, dây lưng nam nữ nguyên giá, Trang phục WarmMax nguyên giá (Trừ Áo dài, Firstclass, Đồ lót)
                    </Typography>
                    <Typography>
                    Không áp dụng cùng ưu đãi khác (bao gồm cả ưu đãi thẻ Vip)
                    </Typography>
                    <Typography>
                    Mỗi Qúy khách là thành viên của <strong>VIETINBANK ĐÔNG HÀ NỘI</strong> nhận 01 MÃ ƯU ĐÃI.
                    </Typography>
                    
                    <Typography>HSD: Mã ưu đãi có thời gian sử dụng đến hết ngày 29/02/2024. Áp dụng tại 8 showroom FORMAT HÀ NỘI</Typography>
                    <Typography>100 Trần Duy Hưng - 212 Bà Triệu - 251 Cầu Giấy - 341 Nguyễn Văn Cừ - 49 Thái Hà - 197 Lạc Long Quân - 32 Trần Phú, Hà Đông - 82 Lê Lợi, Hà Đông.</Typography>
                    <Typography>Tổng đài CSKH : 18006136</Typography>
                    {SurveyBrandType.TOKYOLIFE === brand ? (
                      <Typography>
                        Website:{" "}
                        <a href="https://tokyolife.vn/">https://tokyolife.vn/</a>
                      </Typography>
                    ) : (
                      <Typography>
                        Website:{" "}
                        <a href="https://format.com.vn">https://format.com.vn</a>
                      </Typography>
                    )}
  
                    <Typography style={{ fontWeight: "bold" }}>
                      Nếu có thêm bất kỳ ý kiến đóng góp nào bạn có thể gửi đến
                      mail của chúng tôi:{" "}
                      {SurveyBrandType.TOKYOLIFE === brand
                        ? "cskh-tokyolife@forlife.vn"
                        : "cskh@format.vn"}
                    </Typography>
                  </div>
                  <p style={{ textAlign: "center" }}>
                    <Button
                      variant="outlined"
                      onClick={(e) => {
                        e.preventDefault();
                        return exportComponentAsJPEG(componentRef);
                      }}
                      sx={{ mt: 2 }}
                    >
                      Tải về máy
                    </Button>
                  </p>
                </Box>
              </Card>
            </Box>
          </Container>
        </Box>
      </form>
    );
  }
  
  export default Viettinbank;
  