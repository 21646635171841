import React, { useCallback, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { Box, Container, Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useParams, useSearchParams } from "react-router-dom";
import { Formik, isObject, useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import fetchApi from "../../lib/axios";
import { convertTimestamp, createMarkup, getKeyByValue, htmlDecode } from "../../utils/common";
import QuestionType from "../../constants/question-type";
import SurveyBrandType from "../../constants/survey-brand-type";
import Logo from "./Logo";
import { isEmail, validatePhone } from "../../utils/validate";
import Notfound from "../Notfound";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import FormSection from "./FormSection";
import FormSuccess2 from "./FormSuccess2";
import FormSuccess3 from "./FormSuccess3";
import Cookies from "../../utils/cookies";
import FormSuccess4 from "./FormSuccess4";
import Heritage from "../../components/successes/Heritage";
import Viettinbank from "../../components/successes/Viettinbank";

Form.propTypes = {};

function Form(props) {
  const { id } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = React.useState({});
  const [answers, setAnswers] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isSubmiting, setSubmiting] = React.useState(false);
  const [isSubmited, setIsSubmited] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const [phone, setPhone] = React.useState('');
  const [name, setName] = React.useState(null);
  const [surveyCode, setCode] = React.useState([]);
  const [surveyCustomType, setSurveyCustomType] = React.useState(null);

  const orderId = searchParams.get("order_id")
    ? searchParams.get("order_id")
    : null;
  const orderTime = searchParams.get("order_time")
    ? searchParams.get("order_time")
    : null;
  const branchid = searchParams.get("branchid")
    ? searchParams.get("branchid")
    : null;
  const empid = searchParams.get("empid")
    ? searchParams.get("empid")
    : null;

  const navigate = useNavigate();

  const prepareRequiredAnswers = useCallback((formData) => {
    const requiredQuestions = formData.questionnaires
      .filter((question) => {
        return question.isRequired && question.type === QuestionType.DROPDOWN;
      })
      .map((question) => {
        if (question.options.length > 0) {
          return {
            questionId: question._id,
            answer: question.options[0].value,
          };
        } else {
          return {
            questionId: question._id,
            answer: "",
          };
        }
      });

    return requiredQuestions;
  });

  const validateQuestions = (sections) => {
    let errors = {};

    sections.forEach((section) => {
      section.questionnaires.forEach((question) => {
        const findIdx = answers.findIndex((item) => {
          return (
            item.questionId === question._id &&
            (item.answer?.length > 0 || item.answer !== {})
          );
        });

        const fieldKey = getKeyByValue(data.config, question._id);

        if (
          fieldKey === "phone_number" &&
          findIdx > -1 &&
          !validatePhone(answers[findIdx]["answer"])
        ) {
          errors[question._id] = "Số điện thoại không hợp lệ";
        }

        if (
          fieldKey === "email" &&
          findIdx > -1 &&
          !isEmail(answers[findIdx]["answer"])
        ) {
          errors[question._id] = "Địa chỉ email không hợp lệ";
        }

        if (question.isRequired && findIdx === -1) {
          errors[question._id] = "Đây là câu hỏi bắt buộc phải trả lời";
        }
      });
    });

    return errors;
  };

  const handleSubmit = async () => {
    setSubmiting(true);

    try {
      const submitData = {
        surveyFormId: id,
        answers,
        customer: {
          name: searchParams.get("name") ? searchParams.get("name") : "",
          phone: searchParams.get("phone"),
        },
      };

      const validateErrors = validateQuestions(data.sections);

      if (Object.keys(validateErrors).length > 0) {
        setErrors(validateErrors);

        setSubmiting(false);
        return;
      }

      let res = await fetchApi(`/surveys/${id}`, submitData, "post");

      if (data.is_survey_code) {
        setCode([...surveyCode, res.code]);

        if (
          ["64b0b8fa7e94f0fa34a5bf10", "64b0b6693138e2378d92e538"].includes(id)
        ) {
          Cookies.set("format_coupon_14072023", res.code);
        } else if (
          ["650400b5ead2469e82875712", "6507b8595b20c43144f9d9aa"].includes(id)
        ) {
          Cookies.set("format_coupon_18092023", res.code);
        } else if (["659b58722005e5d721ae89c0"].includes(id)) {
          Cookies.set("format_heritage_08012024", res.code);
        } else if (["659b58e02005e5d721ae89d7"].includes(id)) {
          Cookies.set("format_vietinbank_08012024", res.code);
        } else {
          Cookies.set("format_coupon", res.code);
        }
      } else {
        setTimeout(() => {
          setSubmiting(false);
          navigate(`/sv/${id}/success`);
        }, 1000);
      }
    } catch (error) {
      setSubmiting(false);
      console.log(error);
    }
  };

  const handleChange = (type, questionId, answer, isMoreAnswer = false) => {
    setErrors({});

    const newAnswers = [...answers];

    if (type === QuestionType.CHECKBOXES) {
      const index = newAnswers.findIndex(
        (answer) => answer.questionId === questionId
      );

      if (index === -1) {
        if (isMoreAnswer) {
          newAnswers.push({
            questionId,
            answer: [],
            more_answer: answer,
          });
        } else {
          newAnswers.push({ questionId, answer: [answer], more_answer: "" });
        }
      } else {
        if (isMoreAnswer) {
          newAnswers[index].more_answer = answer;
        } else {
          const vIndex = newAnswers[index].answer.findIndex(
            (v) => v === answer
          );

          if (vIndex === -1) {
            newAnswers[index].answer.push(answer);
          } else {
            newAnswers[index].answer.splice(vIndex, 1);
          }
        }
      }
    } else {
      const index = newAnswers.findIndex(
        (answer) => answer.questionId === questionId
      );

      if (index === -1) {
        if (isMoreAnswer) {
          newAnswers.push({
            questionId,
            more_answer: answer,
          });
        } else {
          newAnswers.push({ questionId, answer });
        }
      } else {
        if (isMoreAnswer) {
          newAnswers[index].more_answer = answer;
        } else {
          newAnswers[index].answer = answer;
        }
      }
    }

    setAnswers(newAnswers);
  };

  const handleRemoveAnswerFile = (qid) => {
    const newAnswers = answers.filter((item) => item.questionId !== qid);

    setAnswers(newAnswers);
  };

  const setDefaultValues = (sections) => {
    if (!sections || !orderId || !orderTime) return;

    let defaultValues = [];

    sections.forEach((section) => {
      section.questionnaires.forEach((question) => {
        if (question.type === QuestionType.SHORT_ANSWER) {
          if (question.title === "Mã đơn") {
            defaultValues.push({
              answer: orderId,
              questionId: question._id,
            });
          } else if (question.title === "Thời gian mua hàng") {
            defaultValues.push({
              answer: decodeURIComponent(orderTime).replace(/\./g, " "),
              questionId: question._id,
            });
          } else if (question.title === "Mã trung tâm") {
            defaultValues.push({
              answer: decodeURIComponent(branchid),
              questionId: question._id,
            });
          } else if (question.title === "Mã nhân viên") {
            defaultValues.push({
              answer: decodeURIComponent(empid),
              questionId: question._id,
            });
          }
        }
      });
    });

    setAnswers([...answers, ...defaultValues]);
  };

  useEffect(() => {
    const phone = searchParams.get("phone");
    const name = searchParams.get("name");

    if (phone && validatePhone(phone)) {
      setPhone(phone);
    }

    setName(name);

    async function fetchData() {
      try {
        const res = await fetchApi(`/surveys/${id}`);
        setData(res); 
        setDefaultValues(res.sections);
        setAnswers(prepareRequiredAnswers(res));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }

    fetchData();

    const format_heritage_coupon = Cookies.get("format_heritage_08012024");
    const format_vietinbank_coupon = Cookies.get("format_vietinbank_08012024");

    let arr = [];

    if (format_heritage_coupon) {
      arr.push(format_heritage_coupon);
      setCode([...surveyCode, format_heritage_coupon]);
    }

    if (format_vietinbank_coupon) {
      arr.push(format_vietinbank_coupon);
    }

    setCode([...arr]);
  }, []);

  if (isLoading) {
    return (
      <div style={{ textAlign: "center", paddingTop: "20px" }}>Đang tải...</div>
    );
  }

  // if (phone === null && !data.is_survey_code || Object.keys(data).length === 0) return <Notfound />;

  if (
    surveyCode.includes(Cookies.get("format_heritage_08012024")) &&
    id == "659b58722005e5d721ae89c0"
  ) {
    return (
      <Heritage
        brand={data.brand}
        code={Cookies.get("format_heritage_08012024")}
      />
    );
  }

  if (
    surveyCode.includes(Cookies.get("format_vietinbank_08012024")) &&
    id == "659b58e02005e5d721ae89d7"
  ) {
    return (
      <Viettinbank
        brand={data.brand}
        code={Cookies.get("format_vietinbank_08012024")}
      />
    );
  }

  return (
    <div>
      <form
        style={{
          minHeight: "100vh",
          height: "100%",
          background: `${
            SurveyBrandType.TOKYOLIFE === data.brand ? "#FFEDE8" : " #FAFAFA"
          }`,
        }}
      >
        <Box
          sx={{
            display: "flex",
            height: "100%",
            pb: 3,
          }}
        >
          <Container maxWidth="md">
            <Logo brand={data.brand} />

            {data.sections?.map((section, index) => {
              return (
                <FormSection
                  key={`section-${index}`}
                  data={section}
                  answers={[...answers]}
                  name={name}
                  onChange={handleChange}
                  onRemoveFile={handleRemoveAnswerFile}
                  style={{ marginBottom: "20px" }}
                  errors={errors}
                />
              );
            })}

            {
              <Box sx={{ mt: 2, display: "flex", justifyContent: "start" }}>
                <Button
                  variant="contained"
                  color="error"
                  sx={{
                    background: `${
                      SurveyBrandType.TOKYOLIFE === data.brand
                        ? "#C81F24"
                        : "#656565"
                    }`,
                  }}
                  disabled={isSubmiting}
                  onClick={() => handleSubmit()}
                >
                  Gửi đi
                </Button>
              </Box>
            }
          </Container>
        </Box>
      </form>
    </div>
  );
}

export default Form;
