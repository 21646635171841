import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { createMarkup, htmlDecode } from "../../utils/common";
import SurveyBrandType from "../../constants/survey-brand-type";
import QuestionType from "../../constants/question-type";
import { LoadingButton } from "@mui/lab";
import FileAnswer from "./Question/FileAnswer";
import MultipleChoice from "./Question/MultipleChoice";
import ShortAnswer from "./Question/ShortAnswer";
import CheckBoxs from "./Question/CheckBoxs";
import Dropdown from "./Question/Dropdown";

FormSection.propTypes = {
  data: PropTypes.object,
  name: PropTypes.string,
  answers: PropTypes.array,
  errors: PropTypes.any,
  style: PropTypes.any,
  onChange: PropTypes.func,
  onRemoveFile: PropTypes.func,
};

function FormSection({
  data,
  name,
  errors,
  style,
  onChange,
  answers,
  onRemoveFile,
}) {
  return (
    <Box style={style}>
      <Box>
        <Card>
          <Stack
            alignItems="center"
            sx={{
              p: 2,
              borderTop: `6px solid ${
                SurveyBrandType.TOKYOLIFE === data.brand ? "#C81F24" : "#656565"
              }`,
            }}
          >
            <Typography variant="h5">
              <strong>{data.title}</strong>
            </Typography>
          </Stack>
          {data.description?.length > 0 && (
            <Box>
              <Divider />
              <Box sx={{ p: 2 }}>
                <div className="ck-content">
                  <div
                    dangerouslySetInnerHTML={createMarkup(
                      htmlDecode(data.description)
                    )}
                  />

                  <div
                    style={{
                      clear: "both",
                    }}
                  ></div>
                </div>
              </Box>
            </Box>
          )}

          <Divider />

          <Box sx={{ p: 2 }}>
            {name && (
              <Stack sx={{ mb: 1 }}>
                <Typography sx={{ mr: 1 }}>
                  Xin chào <strong>{name}</strong>, chào mừng bạn đến với trang
                  khảo sát của chúng tôi!
                </Typography>
              </Stack>
            )}

            <Box direction="row">
              <Typography color="error" sx={{ mr: 1, fontSize: "0.75rem" }}>
                * Yêu cầu bắt buộc
              </Typography>
            </Box>
          </Box>
        </Card>
      </Box>

      {data.questionnaires?.map((question, index) => {
        const answer = answers.find((item) => item.questionId === question._id);

        return (
          <Box key={`question-${question._id}`} sx={{ mt: 2 }}>
            <Card sx={{ p: 2 }}>
              <Box sx={{ mb: 2 }}>
                <Stack direction="row">
                  <strong>{question.title} </strong>
                  {question.isRequired && (
                    <Typography color="error" sx={{ ml: 1 }}>
                      *
                    </Typography>
                  )}
                </Stack>
              </Box>
              <Box style={{ width: "100%" }}>
                {question.type === QuestionType.FILE && (
                  <FileAnswer
                    question={question}
                    value={answer}
                    onChange={onChange}
                    onRemoveFile={onRemoveFile}
                  />
                )}

                {question.type === QuestionType.SHORT_ANSWER && (
                  <ShortAnswer question={question} defaultValue={answer?.answer} onChange={onChange} />
                )}

                {question.type === QuestionType.MULTIPLE_CHOICE && (
                  <MultipleChoice question={question} onChange={onChange} />
                )}

                {question.type === QuestionType.CHECKBOXES && (
                  <CheckBoxs question={question} onChange={onChange} />
                )}

                {question.type === QuestionType.DROPDOWN && (
                  <Dropdown question={question} onChange={onChange} />
                )}

                {errors[question._id] && (
                  <FormHelperText error>
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: 12,
                      }}
                    >
                      <ErrorOutlineIcon sx={{ mr: 1 }} fontSize="small" />{" "}
                      {errors[question._id]}
                    </Typography>
                  </FormHelperText>
                )}
              </Box>
            </Card>
          </Box>
        );
      })}
    </Box>
  );
}

export default FormSection;
