import logo from "./assets/image/tokyolife.png";
import { Box, Container, Card, Divider } from "@mui/material";
import "./App.css";
import { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Form from "./pages/main/Form";
import FormSuccess from "./pages/main/FormSuccess";
import Notfound from "./pages/Notfound";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/sv/:id" element={<Form />} />
        <Route path="/sv/:id/success" element={<FormSuccess />} />
        <Route path="*" element={<Notfound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
