import { Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Stack, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import fetchApi from "../../lib/axios";
import Logo from "./Logo";
import SurveyBrandType from "../../constants/survey-brand-type";
import PropTypes from 'prop-types';

function CouponDialog(props) {
  const { onClose, open } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Set backup account</DialogTitle>
      <DialogContent>
        ssssssssss
      </DialogContent>
      <DialogActions>
          <Button variant="contained" color="error" size="small" onClick={handleClose}>
            Đóng
          </Button>
        </DialogActions>
    </Dialog>
  );
}

CouponDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

function FormSuccess(props) {
  const [data, setData] = React.useState({});
  const { id } = useParams();
  const [openDialog, setOpenDialog] = useState(true)

  useEffect(() => {
    async function fetchData() {
      const res = await fetchApi(`/surveys/${id}`);

      setData(res);
    }

    fetchData();
  }, []);

  return (
    <form style={{ minHeight: "100vh", height: "100%", background: `${
      SurveyBrandType.TOKYOLIFE === data.brand ? "#FFEDE8" : " #FAFAFA"
    }`, }}>
      <Box
        sx={{
          display: "flex",
          height: "100%",
          pb: 3,
        }}
      >
        <Container maxWidth="md">
          <Logo brand={data.brand} />
          <Box>
            <Card>
              <Stack
                alignItems="center"
                sx={{
                  p: 2,
                  borderTop: `6px solid ${
                    SurveyBrandType.TOKYOLIFE === data.brand
                      ? "#C81F24"
                      : "#656565"
                  }`,
                }}
              >
                <Typography variant="h5">
                  <strong>{data.title}</strong>
                </Typography>
              </Stack>
              <Divider />
              <Box sx={{ p: 2, textAlign: "center" }}>
                <Typography variant="h4">Xin cám ơn!</Typography>
                <Typography variant="div">
                  Khảo sát của bạn đã được gửi.&nbsp;
                </Typography>
                <Typography variant="div">
                  Chúng tôi xin ghi nhận ý kiến đóng góp của bạn, cảm ơn bạn đã
                  dành thời gian hoàn thành khảo sát của chúng tôi.
                </Typography>
                <Typography variant="div">
                  &nbsp;Nếu có thêm bất kỳ ý kiến đóng góp nào bạn có thể gửi
                  đến mail của chúng tôi:{" "}
                  {SurveyBrandType.TOKYOLIFE === data.brand
                    ? "cskh-tokyolife@forlife.vn"
                    : "cskh@format.vn"}
                </Typography>
              </Box>
            </Card>
          </Box>
        </Container>
        
      </Box>

      {/* <CouponDialog open={openDialog} onClose={() => {setOpenDialog(false)}} /> */}
    </form>
  );
}

export default FormSuccess;
