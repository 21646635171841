function validatePhone(phone) {
  var reg = /((09|03|07|08|05)+([0-9]{8})\b)/g;
  if (reg.test(`${phone}`)) {
    return true;
  } else {
    return false;
  }
}

function isEmail(inputText) {
  return inputText.match(/[^\s@]+@[^\s@]+\.[^\s@]+/gi)
}

export { validatePhone, isEmail };
