import jsCookies from 'js-cookie'

export default class Cookies {
  static get(key) {
    return jsCookies.get(key)
  }

  static set(key, value) {
    jsCookies.set(key, value)
  }
  
  static remove(key) {
    return jsCookies.remove(key)
  }
}
