export default class QuestionType {
  static SHORT_ANSWER = "short_answer";
  static PARAGRAPH = "paragraph";
  static MULTIPLE_CHOICE = "multiple_choice";
  static CHECKBOXES = "checkboxes";
  static DROPDOWN = "dropdown";
  static FILE = "file";
}

QuestionType[QuestionType.SHORT_ANSWER] = "Viết câu trả lời";
QuestionType[QuestionType.PARAGRAPH] = "Viết câu trả lời dài";
QuestionType[QuestionType.MULTIPLE_CHOICE] = "Trắc nghiệm";
QuestionType[QuestionType.CHECKBOXES] = "Chọn nhiều đáp án";
QuestionType[QuestionType.DROPDOWN] = "Menu thả xuống";
QuestionType[QuestionType.FILE] = "Ảnh";
